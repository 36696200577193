import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice.js";
import { setLoading } from "../../redux/dataSlices/loadingSlice.js";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card.jsx";
import { planningObjectFormFields } from "./planningFormFields.jsx"
import HandleOnChange from "../../helpers/handleOnChange.jsx";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue.jsx"
import DeepCopy from "../../helpers/DeepCopy.jsx";
import { planningObjectTypeChoices } from "../../selectfieldchoices/planningselect.mjs";
import { GetPlanningObjectById, PutPlanningObject } from "../../actablueAPI/planning/Planning.jsx";
import { getPlanningObjectId } from "../../redux/dataSlices/planningSlice.js";
import PlanningObjectForm from "../../components/planningObjectForm.jsx";
import HandleOnChangeOpeningTimes from "../../helpers/handleOnChangeOpeningTimes.jsx";
import { validateFormObject } from "../../helpers/validateFormObject.jsx";
import { setActiveTab } from "../../redux/dataSlices/formTabSlice.js";
import useHandleError from "../../customhooks/useHandleError.jsx";
import axios from "axios";
import GetDevicesListAll from "../../actablueAPI/devices/GetDevicesListAll.jsx";
import { getDevicesAll, getHashDevicesAll, setDevicesAll } from "../../redux/dataSlices/deviceSlice.js";

const PlanningObjectEdit = () => {
  const token = useSelector(getToken);
  const planningObjectId = useSelector(getPlanningObjectId);
  const devices = useSelector(getDevicesAll);
  const hash_devices_all = useSelector(getHashDevicesAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planningObject, setPlanningObject] = useState({})
  const [invalidFields, setInvalidFields] = useState([]);
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newPlanningObject = HandleOnChange({ event: event, object: planningObject })
    setPlanningObject(newPlanningObject)
  };

  const onDeviceChange = (event) => {
    let newPlanningObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planningObject, selectfield: 'device_id' })
    setPlanningObject(newPlanningObject)
  };

  const onTypeChange = (event) => {
    let newPlanningObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planningObject, selectfield: 'type' })
    setPlanningObject(newPlanningObject)
  };

  const onChangeOpeningTimes = (event) => {
    let newPlanningObject = HandleOnChangeOpeningTimes({ event: event, object: planningObject })
    setPlanningObject(newPlanningObject)
  };

  const onCancel = () => { navigate(-1) }

  const onSubmit = (event) => {
    const [invalid] = validateFormObject({ object: planningObject, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let newPlanningObject = DeepCopy(planningObject)
    newPlanningObject.type = planningObject.type.toUpperCase()

    if (newPlanningObject.opening_times) {
      for (const opening_time of newPlanningObject.opening_times) {
        opening_time.week_day = opening_time.day
        delete opening_time.location_id
        delete opening_time.day
      }
    }

    PutPlanningObject({ token: token, data: newPlanningObject })
      .then(() => {
        navigate("/planning");
      })
      .catch(error => {
        HandleError({ error: error })
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: planningObjectFormFields,
    field: {
      onChange: onChange
    },
    hash_devices_all: hash_devices_all,
    data: planningObject,
    device_id: {
      name: 'device_id',
      options: devices,
      selected: '',
      onChange: onDeviceChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      name: 'type',
      options: planningObjectTypeChoices,
      selected: '',
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    opening_times: {
      onChange: onChangeOpeningTimes
    }
  };

  const editCard = {
    size: 12,
    title: "Planning object edit",
    data: <PlanningObjectForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setLoading(true))

    axios.all([
      GetPlanningObjectById({ token: token, id: planningObjectId }),
      GetDevicesListAll({ token: token })
    ])
      .then(axios.spread((planningObjectsResponse, devicesResponse) => {
        let planningObject = DeepCopy(planningObjectsResponse.data)

        if (planningObject.opening_times) {
          for (const opening_time of planningObject.opening_times) {
            opening_time.day = opening_time.week_day
            delete opening_time.week_day
          }
        }

        setPlanningObject(planningObject)
        dispatch(setDevicesAll(devicesResponse.data))
        dispatch(setLoading(false))
      }))
      .catch((error) => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    dispatch(setActiveTab('main'));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )
};
export default PlanningObjectEdit;
