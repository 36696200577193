import * as React from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Search from "../static/icons/search.svg";
import "./css/searchAndFilter.css"
import SelectField from "../components/selectField";
import { Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const SearchAndFilter = (props) => {
  const { t: translate } = useTranslation();

  if (props.hasOwnProperty('search') && props.hasOwnProperty('filter')) {
    return (
      <Col md={12}>
        <ul className="horizontal-list">
          <li>
            <InputGroup className="searchAndFilter-search">
              <Form.Control placeholder={translate('Search...')} className="search-input" onChange={props.search.onChange} value={props.search.value} onKeyDown={props.search.onKeyDown} />
              <Button className='btn-no-shadow' onClick={props.search.onClick}>
                <img className="search-button" src={Search} alt=''></img>
                {translate('Search')}
              </Button>
            </InputGroup>
          </li>
          <li>
            <div className="searchAndFilter-filter">
              <label htmlFor='navbar-select' className="filter-label">
                {translate('Filter by:')}
              </label>
              <SelectField {...props.filter} />
            </div>
          </li>
        </ul>
      </Col>
    );
  }
};
export default SearchAndFilter;
