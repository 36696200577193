import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import PlanningCalendar from "../../components/planningCalendar";
import BasicCard from "../../components/card";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PlanningTable from "../../components/planningTable";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { GetAllPlanningObjects, GetAllPlannings, GetPlanningLocationAdminByID, GetPlanningObjects, GetPlannings } from "../../actablueAPI/planning/Planning";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import {
  getCalendarEnd,
  getCalendarLoading,
  getCalendarStart,
  getPlanningObjectPageSize,
  getPlanningObjectPagination,
  getPlanningObjects,
  getPlanningPageSize,
  getPlanningPagination,
  getPlannings,
  getRefresh,
  setPlanningLocation,
  setPlanningObjects,
  setPlannings, setCalendarLoading,
  setHashPlanningsFromPlanningObjects,
  setPlanningObjectsAll
} from "../../redux/dataSlices/planningSlice";
import PlanningButtons from "./planningbuttons";
import TableButton from "../../components/tableButton";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";
import { directionFilterChoices, orderByFilterChoices, orderByFilterChoicesObject, statusFilterChoices } from "../../selectfieldchoices/planningselect.mjs";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import PlanningObjectTable from "../../components/planningObjectTable";
import useHandleError from "../../customhooks/useHandleError";
import UnpaidOrderTable from "../../components/unpaidOrderTable";
import PostOrderSyncUnpaid from "../../actablueAPI/invoicepayments/PostOrderSyncUnpaid";
import { getOrders, setOrders } from "../../redux/dataSlices/orderSlice";
import axios from "axios";

const Planning = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const plannings = useSelector(getPlannings);
  const planning_objects = useSelector(getPlanningObjects);
  const refresh = useSelector(getRefresh);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentPage, setCurrentPage] = useState("calendar");
  const [status, setStatus] = useState(null);
  const [orderBy, setOrderBy] = useState("planningTimestamp");
  const [planningObjectsOrderBy, setPlanningObjectsOrderBy] = useState("name");
  const [direction, setDirection] = useState("ascending");
  const [planningObjectsDirection, setPlanningObjectsDirection] = useState("ascending");
  const planningPagination = useSelector(getPlanningPagination);
  const planningPageSize = useSelector(getPlanningPageSize);
  const planningObjectPagination = useSelector(getPlanningObjectPagination);
  const planningObjectPageSize = useSelector(getPlanningObjectPageSize);
  const calendarStart = useSelector(getCalendarStart);
  const calendarEnd = useSelector(getCalendarEnd);
  const calendarLoading = useSelector(getCalendarLoading);
  const HandleError = useHandleError();
  const orders = useSelector(getOrders);
  const [cardTitle, setCardTitle] = useState("Calendar");
  const { t: translate } = useTranslation();

  const buttons = PlanningButtons.call();
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const planning_object_add = buttons.hasOwnProperty('planning_object_add') ? Object.assign({}, buttons.planning_object_add) : false
  const refreshButton = buttons.hasOwnProperty('refresh') ? Object.assign({}, buttons.refresh) : false

  useEffect(() => {
    dispatch(setNavBarTitle("Planning"))
    // eslint-disable-next-line
  }, [])

  useEffect(() => { // This one is for the "events" tab
    if (!location_id) return
    dispatch(setLoading(true))
    GetPlannings({ token: token, location_id: location_id, page: planningPagination, size: planningPageSize, orderBy: orderBy, direction: direction, status: status })
      .then(response => {
        dispatch(setPlannings(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id, status, orderBy, direction, planningPagination, planningPageSize])

  useEffect(() => { // This one is for the "calendar" tab
    if (!location_id) return
    dispatch(setCalendarLoading(true))
    axios.all([
      GetAllPlanningObjects({ token: token, admin_id: admin_id, location_id: location_id }),
      GetAllPlannings({ token: token, location_id: location_id, page: 0, size: 10, start: calendarStart, end: calendarEnd })
    ])
      .then(axios.spread((planningObjectsResponse, planningsResponse) => {
        dispatch(setPlanningObjectsAll(planningObjectsResponse))
        let hashPlanningObjectsPlannings = {}
        planningsResponse.forEach(planning => {
          planning?.planning_objects.forEach(planningObject => {
            if (!hashPlanningObjectsPlannings[planningObject.planning_object_id]) hashPlanningObjectsPlannings[planningObject.planning_object_id] = {}
            hashPlanningObjectsPlannings[planningObject.planning_object_id][planning?.id] = planning
          })
        })
        dispatch(setHashPlanningsFromPlanningObjects(hashPlanningObjectsPlannings));
      }))
      .catch(error => {
        HandleError({ error: error })
      })
      .finally(() => {
        dispatch(setCalendarLoading(false));
      })
    // eslint-disable-next-line
  }, [, refresh, admin_id, location_id, calendarStart, calendarEnd])

  useEffect(() => {
    if (!location_id) return
    PostOrderSyncUnpaid({ token: token, admin_id: admin_id, location_id: location_id, date: 0 })
      .then(response => {
        const filteredOrders = response?.data?.filter(order => order.status === 'OPEN');
        dispatch(setOrders(filteredOrders));
      })
      .catch(error => {
        HandleError({ error: error });
      })
    // eslint-disable-next-line
  }, [, refresh, admin_id, location_id])

  useEffect(() => {
    if (!location_id) return
    dispatch(setLoading(true))
    GetPlanningObjects({ token: token, admin_id: admin_id, location_id: location_id, page: planningObjectPagination, size: planningObjectPageSize, orderBy: planningObjectsOrderBy, direction: planningObjectsDirection })
      .then(response => {
        dispatch(setPlanningObjects(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id, status, planningObjectsOrderBy, planningObjectsDirection, planningObjectPageSize, planningObjectPagination])

  useEffect(() => {
    if (!location_id) return
    GetPlanningLocationAdminByID({ token: token, id: location_id })
      .then(response => {
        dispatch(setPlanningLocation(response.data))
      })
      .catch(error => {
        dispatch(setPlanningLocation(null))
        HandleError({ error: error })
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id])

  useEffect(() => {
    if (calendarLoading) setCardTitle("Calendar (Loading data...)")
    if (!calendarLoading) setCardTitle("Calendar")
  }, [calendarLoading])

  const handlePageChange = (page) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(page);
      setIsTransitioning(false);
    }, 250); // Change this value to adjust the duration of the transition, the one in the CSS too (1000 here is 1s in CSS)
  };

  const onStatusChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setStatus(value)
  }

  const onOrderByChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setOrderBy(value)
  }

  const onDirectionChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setDirection(value)
  }

  const onOrderByChangeObject = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setPlanningObjectsOrderBy(value)
  }

  const onDirectionChangeObject = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setPlanningObjectsDirection(value)
  }

  const calendarCard = {
    size: 12,
    title: cardTitle,
    data: location_id ? <PlanningCalendar orderBy={orderBy} direction={direction} status={status} /> : 'Please select a location or administration',
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const unpaidOrderCard = {
    size: 12,
    title: 'Unpaid Orders',
    data: location_id ? <UnpaidOrderTable orders={orders} /> : 'Please select a location or administration',
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const eventsCard = {
    size: 12,
    title: 'Planning Events',
    data: location_id ? <PlanningTable plannings={plannings} /> : 'Please select a location or administration',
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const planningObjectsCard = {
    size: 12,
    title: 'Planning Objects',
    data: location_id ? <PlanningObjectTable planning_objects={planning_objects} /> : 'Please select a location or administration',
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const searchAndFilter = {
    filters: [
      {
        label: 'Status',
        options: statusFilterChoices,
        onChange: onStatusChange,
        selected: status ? { 'value': statusFilterChoices.find(element => element.id === status)?.id, 'label': statusFilterChoices.find(element => element.id === status)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Order By',
        options: orderByFilterChoices,
        onChange: onOrderByChange,
        selected: orderBy ? { 'value': orderByFilterChoices.find(element => element.id === orderBy)?.id, 'label': orderByFilterChoices.find(element => element.id === orderBy)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Direction',
        options: directionFilterChoices,
        onChange: onDirectionChange,
        selected: direction ? { 'value': directionFilterChoices.find(element => element.id === direction)?.id, 'label': directionFilterChoices.find(element => element.id === direction)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  const searchAndFilterObject = {
    filters: [
      {
        label: 'Order By',
        options: orderByFilterChoicesObject,
        onChange: onOrderByChangeObject,
        selected: planningObjectsOrderBy ? { 'value': orderByFilterChoicesObject.find(element => element.id === planningObjectsOrderBy)?.id, 'label': orderByFilterChoicesObject.find(element => element.id === planningObjectsOrderBy)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Direction',
        options: directionFilterChoices,
        onChange: onDirectionChangeObject,
        selected: planningObjectsDirection ? { 'value': directionFilterChoices.find(element => element.id === planningObjectsDirection)?.id, 'label': directionFilterChoices.find(element => element.id === planningObjectsDirection)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  return (
    <>
      <Col md={12}>
        <div className='row g-3'>
          <div className='col'>
            <div className="p-3 my-3">
              <Button className={`${currentPage === 'calendar' ? "sm-border" : "no-bg no-border"} btn-no-shadow`} type="button" onClick={() => handlePageChange('calendar')}>
                {translate('Timeline')}
              </Button>
              <Button className={`${currentPage === 'events' ? "sm-border" : "no-bg no-border"} btn-no-shadow`} type="button" onClick={() => handlePageChange('events')}>
                {translate('Plannings')}
              </Button>
              <Button className={`${currentPage === 'planning-objects' ? "sm-border" : "no-bg no-border"} btn-no-shadow`} type="button" onClick={() => handlePageChange('planning-objects')}>
                {translate('Planning Objects')}
              </Button>
            </div>
          </div>
          <div className='col-3'>
            <div className="mx-5">
              {currentPage === 'events' && <SearchAndMultiFilterSingleSelect {...searchAndFilter} />}
              {currentPage === 'planning-objects' && <SearchAndMultiFilterSingleSelect {...searchAndFilterObject} />}
            </div>
          </div>
          <div className='col-1'>
            <div className="p-3 my-3">
              {(refreshButton && currentPage === 'calendar') && <TableButton {...refreshButton} />}
              {(add && currentPage === 'events') && <TableButton {...add} />}
              {(planning_object_add && currentPage === 'planning-objects') && <TableButton {...planning_object_add} />}
            </div>
          </div>
        </div>
      </Col>

      {currentPage === 'calendar' &&
        <>
          <BasicCard {...calendarCard} />
          <BasicCard {...unpaidOrderCard} />
        </>
      }

      {currentPage === 'events' &&
        <BasicCard {...eventsCard} />
      }

      {currentPage === 'planning-objects' &&
        <BasicCard {...planningObjectsCard} />
      }
    </>
  )
};

export default Planning;