import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getLocationId } from "../../redux/dataSlices/locationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import LocationForm from "../../components/locationForm";
import LocationFormFields from "./locationFormFields.jsx";
import ReservationLocationFormFields from "./reservationLocationFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import HandleOnChangeMultiObjectField from "../../helpers/handleOnChangeMultiObjectFields";
import HandleOnChangeCheckbox from "../../helpers/handleOnChangeCheckbox";
import AddObject from "../../helpers/addObject";
import RemoveObject from "../../helpers/removeObject";
import HandleOnChangeOpeningTimes from "../../helpers/handleOnChangeOpeningTimes";
import { locationPriceIndicationChoices } from "../../selectfieldchoices/locationselect.mjs";
import CheckNewObjects from "../../helpers/checkNewObjects";
import { applicationChoices } from "../../selectfieldchoices/reservationlocationselect.mjs";
import { getSelectedLocation_id, setSelectedLocation } from "../../redux/dataSlices/selectLocationSlice";
import HandleOnChangeServiceHours from "../../helpers/handleOnChangeServiceHours";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import HandleOnChangeMultiObjectSingleSelectFieldWithValue from "../../helpers/handleOnChangeMultiObjectSingleSelectFieldWithValue";
import { phoneNumberTypeChoices, emailTypeChoices } from "../../selectfieldchoices/phonenumberselect.mjs";
import GetLocationById from "../../actablueAPI/locations/GetLocationByID.jsx";
import GetReservationLocationAdminById from "../../actablueAPI/reservations/GetReservationLocationAdminByID.jsx";
import PostLocation from "../../actablueAPI/locations/PostLocation.jsx";
import PostReservationLocation from "../../actablueAPI/reservations/PostReservationLocation.jsx";
import PlanningLocationFormFields from "./planningLocationFormFields.jsx";
import { GetPlanningLocationAdminByID, PostPlanningLocation } from "../../actablueAPI/planning/Planning.jsx";
import DeepCopy from "../../helpers/DeepCopy.jsx";
import useHandleError from "../../customhooks/useHandleError.jsx";

const LocationEdit = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getLocationId)
  const selected_location_id = useSelector(getSelectedLocation_id)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [location, setLocation] = useState({})
  const [reservationLocation, setReservationLocation] = useState({})
  const [planningLocation, setPlanningLocation] = useState({})
  const [checkedList, setCheckedList] = useState([])
  const navTitle = "Location edit"
  const [id, setId] = useState(0)
  const HandleError = useHandleError();

  const onLocationChange = (event) => {
    let newLocation = HandleOnChange({ event: event, object: location })
    setLocation(newLocation)
  }

  const onReservationLocationChange = (event) => {
    let newReservationLocation = HandleOnChange({ event: event, object: reservationLocation })
    setReservationLocation(newReservationLocation)
  }

  const onPlanningLocationChange = (event) => {
    let newPlanningLocation = HandleOnChange({ event: event, object: planningLocation })
    setPlanningLocation(newPlanningLocation)
  }

  const onImgUrlChange = (event) => {
    let newLocation = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: location, selectfield: 'img_urls' })
    setLocation(newLocation)
  }

  const onReservationImgUrlChange = (event) => {
    let newReservationLocation = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: reservationLocation, selectfield: 'img_urls' })
    setReservationLocation(newReservationLocation)
  }

  const onAreasUrlChange = (event) => {
    let newReservationLocation = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: reservationLocation, selectfield: 'areas' })
    setReservationLocation(newReservationLocation)
  }

  const onPriceIndicationChange = (event) => {
    let newLocation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: location, selectfield: 'price_indication' })
    setLocation(newLocation)
  }

  const onChangeOpeningTimes = (event) => {
    let newLocation = HandleOnChangeOpeningTimes({ event: event, object: location })
    setLocation(newLocation)
  };

  const onChangePlanningOpeningTimes = (event) => {
    let newPlanningLocation = HandleOnChangeOpeningTimes({ event: event, object: planningLocation })
    setPlanningLocation(newPlanningLocation)
  };

  const onChangeServiceHours = (event) => {
    let newReservationLocation = HandleOnChangeServiceHours({ event: event, object: reservationLocation })
    setReservationLocation(newReservationLocation)
  };

  const onChangePhonenumbers = (event) => {
    let newLocation = HandleOnChangeMultiObjectField({ event: event, object: location, field: 'phonenumbers' })
    setLocation(newLocation)
  }

  const onPhonenumbersTypeChange = (event) => {
    let newLocation = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: location, selectfield: 'type', field: 'phonenumbers' })
    setLocation(newLocation)
  }

  const onChangeEmails = (event) => {
    let newLocation = HandleOnChangeMultiObjectField({ event: event, object: location, field: 'emails' })
    setLocation(newLocation)
  }

  const onEmailTypeChange = (event) => {
    let newLocation = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: location, selectfield: 'type', field: 'emails' })
    setLocation(newLocation)
  }

  const onAddObject = (event) => {
    let newId = id + 1
    setId(newId)
    let newLocation = AddObject({ event: event, object: location, id: newId })
    setLocation(newLocation)
  }

  const onRemoveObjects = (event) => {
    let newLocation = RemoveObject({ event: event, object: location, checkedList: checkedList })
    setLocation(newLocation)
    setCheckedList([])
  }

  const onChangeCheckbox = (event) => {
    let newCheckedList = HandleOnChangeCheckbox({ event: event, object: checkedList })
    setCheckedList(newCheckedList)
  }

  const onApplicationChange = (event) => {
    let newReservationLocation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservationLocation, selectfield: 'application' })
    setReservationLocation(newReservationLocation)
  }

  const onApplicationPlanningChange = (event) => {
    let newPlanningLocation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planningLocation, selectfield: 'application' })
    setPlanningLocation(newPlanningLocation)
  }

  const onChangeAllCheckbox = (event) => {

  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    CheckNewObjects(location)

    // why?
    if (location.id === selected_location_id) {
      dispatch(setSelectedLocation(location))
    }

    dispatch(setLoading(true))

    // PostLocation({ token: token, admin_id: admin_id, data: location })
    //   .then(() => {
    //     // if (!scopes.reservations || !typeof(reservationLocation) === 'object') { // needs to be taken care of?
    //       navigate("/locations");
    //     // }
    //   })
    //   .catch(err => {
    //     HandleError(err)
    //     dispatch(setLoading(false))
    //   })

    // if (scopes.reservations && typeof(reservationLocation) === 'object') {
    //   let newReservationLocation = DeepCopy(reservationLocation) // TypeError: Cannot create property 'id' on string ''
    //   if (!reservationLocation.id) {
    //     newReservationLocation.id = location.id
    //   }
    //   newReservationLocation.administration_id = admin_id;

    //   if (window.location.href.includes('humbee')) { // To feed application humbee to the API as default since the form field is hidden
    //     newReservationLocation.application = 'HUMBEE'
    //     newReservationLocation.timezone = 'Europe/Amsterdam'
    //   }
    //   PostReservationLocation({ token: token, admin_id: admin_id, data: newReservationLocation })
    //     .then(() => {
    //       if (!scopes.planning) {
    //         navigate("/locations");
    //       }
    //     })
    //     .catch(err => {
    //       HandleError(err)
    //       dispatch(setLoading(false))
    //     })
    // }

    // if (window.location.href.includes('staging') && scopes.planning && typeof(planningLocation) === 'object') {
    //   let newPlanningLocation = DeepCopy(planningLocation) // TypeError: Cannot create property 'id' on string ''
    //   if (!planningLocation.id) {
    //     newPlanningLocation.id = location.id
    //   }
    //   newPlanningLocation.administration_id = admin_id;

    //   if (newPlanningLocation?.opening_times) {
    //     for (const opening_time of Object.values(newPlanningLocation.opening_times)) {
    //       delete opening_time.location_id
    //     }
    //   }

    //   if (window.location.href.includes('humbee')) { // To feed application humbee to the API as default since the form field is hidden
    //     newPlanningLocation.application = 'HUMBEE'
    //     newPlanningLocation.timezone = 'Europe/Amsterdam'
    //   }

    //   PostPlanningLocation({ token: token, admin_id: admin_id, data: newPlanningLocation })
    //     .then(() => {
    //       navigate("/locations");
    //     })
    //     .catch(err => {
    //       HandleError(err)
    //       dispatch(setLoading(false))
    //     })
    // }

    PostLocation({ token: token, admin_id: admin_id, data: location })
      .then(response => {
        // if (!scopes.reservations) navigate("/locations");
        if (scopes.reservations && typeof (reservationLocation) === 'object') {
          let newReservationLocation = DeepCopy(reservationLocation)
          newReservationLocation.id = response.data.id
          newReservationLocation.administration_id = admin_id;

          if (window.location.href.includes('humbee')) { // To feed application humbee to the API as default since the form field is hidden
            newReservationLocation.application = 'HUMBEE'
            newReservationLocation.timezone = 'Europe/Amsterdam'
          }
          PostReservationLocation({ token: token, admin_id: admin_id, data: newReservationLocation })
            .then(() => {
              if (process.env.REACT_APP_DEVELOP === 'true' && scopes.planning && typeof (planningLocation) === 'object') {
                let newPlanningLocation = DeepCopy(planningLocation)
                if (!planningLocation.id) {
                  newPlanningLocation.id = location.id
                }
                newPlanningLocation.administration_id = admin_id;

                if (newPlanningLocation?.opening_times) {
                  for (const opening_time of Object.values(newPlanningLocation.opening_times)) {
                    delete opening_time.location_id
                  }
                }

                if (window.location.href.includes('humbee')) { // To feed application humbee to the API as default since the form field is hidden
                  newPlanningLocation.application = 'HUMBEE'
                  newPlanningLocation.timezone = 'Europe/Amsterdam'
                }

                PostPlanningLocation({ token: token, admin_id: admin_id, data: newPlanningLocation })
                  .then(() => {
                    navigate("/locations");
                  })
                  .catch(err => {
                    HandleError(err)
                    dispatch(setLoading(false))
                  })
              }
            })
            .catch(err => {
              HandleError(err)
              dispatch(setLoading(false))
            })
        }
        navigate("/locations")
      })
      .catch(err => {
        HandleError(err)
        dispatch(setLoading(false))
      })
  };

  let formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    location: {
      fields: LocationFormFields(),
      field: {
        onChange: onLocationChange
      },
      data: location,
      img_urls: {
        label: 'images',
        options: [],
        selected: [],
        onChange: onImgUrlChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      price_indication: {
        label: 'price_indication',
        options: locationPriceIndicationChoices,
        selected: [],
        onChange: onPriceIndicationChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      opening_times: {
        onChange: onChangeOpeningTimes
      },
      phonenumbers: {
        onChange: onChangePhonenumbers,
        add: {
          title: 'Add',
          type: 'button',
          name: 'phonenumbers',
          onClick: onAddObject
        },
        remove: {
          title: 'Remove',
          type: 'button',
          name: 'phonenumbers',
          onClick: onRemoveObjects
        },
        check: {
          onChange: onChangeCheckbox,
          onChangeAll: onChangeAllCheckbox
        },
        selected: checkedList,
        type: {
          label: 'type',
          options: phoneNumberTypeChoices,
          // selected: {},
          onChange: onPhonenumbersTypeChange,
          clearable: Boolean(false),
          searchable: Boolean(true)
        }
      },
      emails: {
        onChange: onChangeEmails,
        add: {
          title: 'Add',
          type: 'button',
          name: 'emails',
          onClick: onAddObject
        },
        remove: {
          title: 'Remove',
          type: 'button',
          name: 'emails',
          onClick: onRemoveObjects
        },
        check: {
          onChange: onChangeCheckbox,
          onChangeAll: onChangeAllCheckbox
        },
        selected: checkedList,
        type: {
          label: 'type',
          options: emailTypeChoices,
          // selected: {},
          onChange: onEmailTypeChange,
          clearable: Boolean(false),
          searchable: Boolean(true)
        }
      }
    },
  };

  if (scopes.reservations) {
    formData.reservationLocation = {
      fields: ReservationLocationFormFields(),
      field: {
        onChange: onReservationLocationChange
      },
      data: reservationLocation,
      img_urls: {
        label: 'images',
        options: [],
        selected: [],
        onChange: onReservationImgUrlChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      areas: {
        label: 'areas',
        options: [],
        selected: [],
        onChange: onAreasUrlChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      application: {
        label: 'application',
        options: applicationChoices,
        selected: {},
        onChange: onApplicationChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      },
      service_hours: {
        onChange: onChangeServiceHours
      },
    }
  };

  if (scopes.planning) {
    formData.planningLocation = {
      fields: PlanningLocationFormFields(),
      field: {
        onChange: onPlanningLocationChange
      },
      data: planningLocation,
      application: {
        label: 'application',
        options: applicationChoices,
        selected: null,
        onChange: onApplicationPlanningChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      },
      opening_times: {
        onChange: onChangePlanningOpeningTimes
      },
    }
  };

  const editCard = {
    size: 12,
    title: { text: navTitle, option: location.name },
    data: <LocationForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))

    GetLocationById({ token: token, id: location_id })
      .then(response => {
        setLocation(response.data)
        if (!scopes.reservations && !scopes.planning) {
          dispatch(setLoading(false))
        }
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })

    if (scopes.reservations) {
      GetReservationLocationAdminById({ token: token, id: location_id })
        .then(response => {
          setReservationLocation(response.data)
          if (scopes.reservations && !scopes.planning) {
            dispatch(setLoading(false))
          }
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }

    if (scopes.planning) {
      GetPlanningLocationAdminByID({ token: token, id: location_id })
        .then(response => {
          setPlanningLocation(response.data)
          if ((!scopes.reservations && scopes.planning) || (scopes.reservations && scopes.planning)) {
            dispatch(setLoading(false))
          }
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default LocationEdit;

