export const AttributeGroupFormFields = () => {
  const attributeGroupFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'external_id', id: 'external_id', readonly: true },
    { type: 'hidden', name: 'ccv_attributes_id', id: 'ccv_attributes_id', readonly: true },
    { type: 'hidden', name: 'ccv_product', id: 'ccv_product_id', readonly: true },
    { type: 'hidden', name: 'product', id: 'product', readonly: true },

    { type: 'text', name: `Name`, id: 'name' },
    { type: 'text', name: `Type`, id: 'type', readonly: true },

    { type: 'number', name: `Choose min`, id: 'choose_min' },
    { type: 'number', name: `Choose max`, id: 'choose_max' },
    { type: 'checkbox', name: `Add price to main product`, id: 'add_price_to_main_product' },
    { type: 'checkbox', name: `Show addons on receipt`, id: 'show_addons_on_receipt' },
    { type: 'hidden', name: `Auto show`, id: 'auto_show' },

    // single option select fields  

    // multi option select fields
    { type: 'hidden', name: `Products`, id: 'products', formdatafield: 'products' },

    // multi option select fields + self create

    // single option object fields

    // multiple option fields

    // not adjustable fields
    // {type:'datetime-local', name:'creation', id:'creation' ,  readonly:true},
    // {type:'datetime-local', name:'modification', id:'modification' ,  readonly:true},    
  ]

  return attributeGroupFormFields
}

export const AttributegroupAddProductTypeFormField = () => {
  return { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' }
}