import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment';

export const planningSlice = createSlice({
  name: "planning",
  initialState: {
    hash_plannings_all: {},
    hash_planninglocations_all: {},
    hash_planningobjects_all: {},
    hash_plannings_from_planningobjects: {},
    planning_pagination: 0,
    planning_pagesize: 10,
    planningobject_pagination: 0,
    planningobject_pagesize: 10,
    start_date: moment().startOf('month').subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ss'),
    end_date: moment().endOf('month').add(7, 'days').format('YYYY-MM-DDTHH:mm:ss'),
    calendar_view: 'month',
    planning_assign_devices_loading: false
  },
  reducers: {
    setPlanningId: (state, action) => {
      state.planning_id = action.payload;
    },
    setPlanning: (state, action) => {
      state.planning = action.payload;
    },
    setPlannings: (state, action) => {
      state.plannings = action.payload
    },
    setPlanningsAll: (state, action) => {
      state.plannings_all = action.payload
      action?.payload?.forEach(planning => {
        state.hash_plannings_all[planning.id] = planning
      });
    },
    setHashPlannings: (state, action) => {
      state.hash_plannings_all = action.payload
    },
    setPlanningModalShow: (state, action) => {
      state.planning_modalshow = action.payload
    },
    setPlanningPagination: (state, action) => {
      state.planning_pagination = (action.payload - 1)
    },
    setPlanningPageSize: (state, action) => {
      state.planning_pagesize = action.payload
    },
    setPlanningLocationId: (state, action) => {
      state.planninglocation_id = action.payload;
    },
    setPlanningLocation: (state, action) => {
      state.planninglocation = action.payload;
    },
    setPlanningLocations: (state, action) => {
      state.planninglocations = action.payload
    },
    setPlanningLocationsAll: (state, action) => {
      state.planninglocations_all = action.payload
      action?.payload?.forEach(planninglocations => {
        state.hash_planninglocations_all[planninglocations.id] = planninglocations
      });
    },
    setPlanningLocationModalShow: (state, action) => {
      state.planninglocation_modalshow = action.payload
    },
    setPlanningLocationPagination: (state, action) => {
      state.planninglocation_pagination = (action.payload - 1)
    },
    setPlanningLocationPageSize: (state, action) => {
      state.planninglocation_pagesize = action.payload
    },
    setPlanningObjectId: (state, action) => {
      state.planningobject_id = action.payload;
    },
    setPlanningObject: (state, action) => {
      state.planningobject = action.payload;
    },
    setPlanningObjects: (state, action) => {
      state.planningobjects = action.payload
    },
    setPlanningObjectsAll: (state, action) => {
      state.planningobjects_all = action.payload
      action?.payload?.forEach(planning => {
        state.hash_planningobjects_all[planning.id] = planning
      });
    },
    setPlanningObjectModalShow: (state, action) => {
      state.planningobject_modalshow = action.payload
    },
    setPlanningObjectPagination: (state, action) => {
      state.planningobject_pagination = (action.payload - 1)
    },
    setPlanningObjectPageSize: (state, action) => {
      state.planningobject_pagesize = action.payload
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload
    },
    setCalendarStart: (state, action) => {
      state.start_date = action.payload
    },
    setCalendarEnd: (state, action) => {
      state.end_date = action.payload
    },
    setCalendarLoading: (state, action) => {
      state.calendar_loading = action.payload
    },
    setHashPlanningsFromPlanningObjects: (state, action) => {
      state.hash_plannings_from_planningobjects = action.payload;
    },
    setCalendarView: (state, action) => {
      state.calendar_view = action.payload
    },
    setPlanningAssignDevicesLoading: (state, action) => {
      state.planning_assign_devices_loading = action.payload
    }
  }
});

export const {
  setPlanning, setPlanningId, setPlanningModalShow, setPlanningPageSize, setPlanningPagination, setPlannings, setPlanningsAll, setHashPlannings,
  setPlanningLocation, setPlanningLocationId, setPlanningLocationModalShow, setPlanningLocationPageSize, setPlanningLocationPagination, setPlanningLocations, setPlanningLocationsAll,
  setPlanningObject, setPlanningObjectId, setPlanningObjectModalShow, setPlanningObjectPageSize, setPlanningObjectPagination, setPlanningObjects, setPlanningObjectsAll,
  setRefresh,
  setCalendarEnd, setCalendarStart, setCalendarLoading,
  setHashPlanningsFromPlanningObjects,
  setCalendarView,
  setPlanningAssignDevicesLoading
} = planningSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getPlanningId = (state) => state.planning.planning_id
export const getPlanning = (state) => state.planning.planning
export const getPlannings = (state) => state.planning.plannings
export const getPlanningsAll = (state) => state.planning.plannings_all
export const getHashPlanningsAll = (state) => state.planning.hash_plannings_all
export const getPlanningModalShow = (state) => state.planning.planning_modalshow
export const getPlanningPagination = (state) => state.planning.planning_pagination
export const getPlanningPageSize = (state) => state.planning.planning_pagesize

export const getPlanningLocationId = (state) => state.planning.planninglocation_id
export const getPlanningLocation = (state) => state.planning.planninglocation
export const getPlanningLocations = (state) => state.planning.planninglocations
export const getPlanningLocationsAll = (state) => state.planning.planninglocations_all
export const getHashPlanningLocationsAll = (state) => state.planning.hash_planninglocations_all
export const getPlanningLocationModalShow = (state) => state.planning.planninglocation_modalshow
export const getPlanningLocationPagination = (state) => state.planning.planninglocation_pagination
export const getPlanningLocationPageSize = (state) => state.planning.planninglocation_pagesize

export const getPlanningObjectId = (state) => state.planning.planningobject_id
export const getPlanningObject = (state) => state.planning.planningobject
export const getPlanningObjects = (state) => state.planning.planningobjects
export const getPlanningObjectsAll = (state) => state.planning.planningobjects_all
export const getHashPlanningObjectsAll = (state) => state.planning.hash_planningobjects_all
export const getPlanningObjectModalShow = (state) => state.planning.planningobject_modalshow
export const getPlanningObjectPagination = (state) => state.planning.planningobject_pagination
export const getPlanningObjectPageSize = (state) => state.planning.planningobject_pagesize

export const getRefresh = (state) => state.planning.refresh

export const getCalendarStart = (state) => state.planning.start_date
export const getCalendarEnd = (state) => state.planning.end_date
export const getCalendarLoading = (state) => state.planning.calendar_loading

export const getHashPlanningsFromPlanningObjects = (state) => state.planning.hash_plannings_from_planningobjects

export const getCalendarView = (state) => state.planning.calendar_view

export const getPlanningAssignDevicesLoading = (state) => state.planning.planning_assign_devices_loading

export default planningSlice.reducer;