import {
  Menu,
  Item,
  Separator,
  Submenu
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { useDispatch, useSelector } from "react-redux";
import { getHashPlanningObjectsAll, getHashPlanningsFromPlanningObjects, getPlanning, setHashPlanningsFromPlanningObjects, setPlanning, setPlanningId, setPlanningModalShow } from "../redux/dataSlices/planningSlice";
import { useNavigate } from "react-router-dom";
import { PatchPlanningStatus, PutPlanning } from "../actablueAPI/planning/Planning";
import { getToken } from "../redux/dataSlices/tokenSlice";
import useHandleError from "../customhooks/useHandleError";
import PlanningMoveToDeviceModal from "./planningMoveToDeviceModal";
import { useState } from "react";
import { MovePlanningFormFields } from "../portal/planning/planningFormFields";
import DeepCopy from "../helpers/DeepCopy";

const MENU_ID = "planning-calendar-item-menu";

// TODO: Make a reusuable component for this

export default function PlanningCalendarItemMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(getToken);
  const HandleError = useHandleError();
  const planning = useSelector(getPlanning);
  const planningObjectsAll = useSelector(getHashPlanningObjectsAll);
  const plannings = useSelector(getHashPlanningsFromPlanningObjects);
  const [moveModalShow, setMoveModalShow] = useState(false);
  const [selectedPlanningObject, setSelectedPlanningObject] = useState(null);
  const [currentPlanningObjectId, setCurrentPlanningObjectId] = useState(null);

  const onChange = (event) => {
    setSelectedPlanningObject(planningObjectsAll[event?.value])
  };

  const isItemHidden = ({ props, data }) => {
    if (data === "Complete") return (props?.planning?.status === 'COMPLETED')
    if (data === "Accept") return (props?.planning?.status === 'ACCEPTED')
    if (data === "Pending") return (props?.planning?.status === 'PENDING')
    if (data === "Cancel") return (props?.planning?.status === 'CANCELLED')
    return false
  }

  const onMovePlanning = () => {
    let newPlannings = DeepCopy(plannings);
    let newPlanning = DeepCopy(planning);
    const index = newPlanning?.planning_objects.findIndex(object => object.planning_object_id === currentPlanningObjectId)
    if (!newPlanning?.planning_objects.find(object => object.planning_object_id === selectedPlanningObject?.id)) {
      newPlanning.planning_objects[index] = DeepCopy(selectedPlanningObject)
    } else {
      if (newPlanning.planning_objects.length > 1) newPlanning.planning_objects.splice(index, 1);
    }

    if (newPlanning?.planning_objects) {
      for (const planning_object of newPlanning?.planning_objects) {
        if (!planning_object.planning_object_id) planning_object.planning_object_id = planning_object.id
        delete planning_object.id
      }
    }

    newPlanning.device_id = selectedPlanningObject?.device_id

    PutPlanning({ token: token, data: newPlanning })
      .then(response => {
        if (!newPlannings[selectedPlanningObject?.id]) newPlannings[selectedPlanningObject?.id] = {}
        delete newPlannings[currentPlanningObjectId][newPlanning?.id];
        newPlannings[selectedPlanningObject?.id][newPlanning?.id] = response.data;
        dispatch(setHashPlanningsFromPlanningObjects(newPlannings));
      })
      .catch(error => {
        HandleError({ error: error })
      });

    setMoveModalShow(false);
  }

  const modal = {
    modal: {
      data: selectedPlanningObject?.id,
      title: "Select Device",
      tag: "Move",
      show: moveModalShow,
      fields: MovePlanningFormFields(),
      toggleModal: setMoveModalShow,
      move: onMovePlanning,
      planning_object_id: {
        label: 'planning_object_id',
        placeholder: 'Select...',
        options: [],
        selected: [],
        onChange: onChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      },
    }
  }

  const handleItemClick = ({ id, props }) => {
    switch (id) {
      case "view":
        dispatch(setPlanning(props?.planning))
        dispatch(setPlanningModalShow(true))
        break;
      case "edit":
        dispatch(setPlanningId(props?.planning?.id));
        navigate("/planning/edit");
        break;
      case 'move':
        dispatch(setPlanning(props?.planning))
        setCurrentPlanningObjectId(props?.currentPlanningObjectId);
        setMoveModalShow(true);
        break;
      case 'completed':
      case 'accepted':
      case 'pending':
      case 'cancelled':
        let newPlannings = DeepCopy(plannings);
        PatchPlanningStatus({ token: token, id: props?.planning?.id, status: id.toUpperCase() })
          .then(response => {
            for (const planning_object of response?.data?.planning_objects) {
              if (!newPlannings[planning_object?.planning_object_id]) newPlannings[planning_object?.planning_object_id] = {}
              newPlannings[planning_object?.planning_object_id][props?.planning?.id] = response?.data
            }
            dispatch(setHashPlanningsFromPlanningObjects(newPlannings));
          })
          .catch(error => {
            HandleError({ error: error })
          })
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Menu id={MENU_ID}>
        <Item id="view" onClick={handleItemClick}>
          View
        </Item>
        <Item id="edit" onClick={handleItemClick}>
          Edit
        </Item>
        <Item id="move" onClick={handleItemClick}>
          Move to Device
        </Item>
        <Separator />
        <Submenu label="Change status">
          <Item id="completed" onClick={handleItemClick} hidden={isItemHidden} data="Complete">
            Complete
          </Item>
          <Item id="accepted" onClick={handleItemClick} hidden={isItemHidden} data="Accept">
            Accept
          </Item>
          <Item id="pending" onClick={handleItemClick} hidden={isItemHidden} data="Pending">
            Pending
          </Item>
          <Item id="cancelled" onClick={handleItemClick} hidden={isItemHidden} data="Cancel">
            Cancel
          </Item>
        </Submenu>
      </Menu>
      <PlanningMoveToDeviceModal {...modal} />
    </>
  );
}